
@use '~styles/variables' as vars;

.back-button {
  width: 44px;
  height: 44px;
  background-color: vars.$gray;
  border-radius: 5px;
  color: vars.$gray-icon;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
