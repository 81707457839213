
@use '~styles/variables' as vars;

.hm-badge {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon svg {
    color: vars.$gray-darker;
  }
}
