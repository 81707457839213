
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

:global(.icon-arrow-down),
:global(.icon-arrow-right) {
  svg {
    stroke-width: 2;
  }
}
