
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.vehicle-details {
  display: flex;
  flex-direction: column;

  .card-title {
    margin-bottom: 12px;
  }

  .section-title {
    color: vars.$blue;
    margin-bottom: 14px;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}

.card {
  flex-direction: column;
  padding-bottom: 30px;
  margin-bottom: 28px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }

  + .card-title {
    margin-top: 12px;
  }
}

.vin {
  padding: 16px 12px;
  text-align: center;
  width: 100%;
  font-size: 13px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.left {
  margin-bottom: 20px;
}

.right {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.01em;
}

@include mixins.media(lg) {
  .row {
    display: flex;
    align-items: center;

    .left {
      padding-right: 30px;
      width: 50%;
      margin-bottom: 0;
    }

    .right {
      padding-left: 30px;
      width: 50%;
    }
  }

  .hide-on-desktop {
    display: none;
  }
}
