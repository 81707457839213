
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.button {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 16px 25px 18px;
  font-weight: 500;
  text-align: center;
  border-radius: 30px;
  transition: background-color 150ms, color 150ms;

  &,
  > a {
    @include typo.text-medium-alt();
  }

  &:disabled {
    pointer-events: none;
  }
}

.primary {
  color: vars.$gray-darker;
  background: #fff;

  &:hover {
    color: #3d4045;
    background: #e3e1e0;
  }

  &:active {
    color: #3d4045;
    background: #d1d0cf;
  }

  &:disabled {
    color: #4a4e55;
    background: #75787e;
  }
}

.secondary {
  color: vars.$gray-light;
  background: rgba(vars.$gray-1, 0.9);

  &:hover {
    background: vars.$gray-1;
  }

  &:active {
    background: #d1d0cf;
  }

  &:disabled {
    color: vars.$gray-light;
    background: rgba(vars.$gray-1, 0.03);
  }
}

.danger {
  color: #fff;
  background: vars.$red;

  &:hover {
    background: #db4945;
  }

  &:active {
    background: #e64d48;
  }

  &:disabled {
    color: #4a4e55;
    background: #75787e;
  }
}

.clear {
  background: transparent;
}

.dark {
  color: vars.$gray-text;
  background: vars.$gray;

  &:hover {
    background: vars.$gray-darker;
  }

  &:active {
    background: vars.$gray-darker;
  }

  &:disabled {
    background: vars.$gray-lightest;
  }
}
