@font-face {
  font-weight: 400;
  font-family: 'Roboto';
  font-style: normal;
  src: url('~src/assets/fonts/roboto/roboto-v20-latin-regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-regular.woff') format('woff'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg');
}

@font-face {
  font-weight: 500;
  font-family: 'Roboto';
  font-style: normal;
  src: url('~src/assets/fonts/roboto/roboto-v20-latin-500.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-500.woff') format('woff'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-500.ttf') format('truetype'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-500.svg#Roboto') format('svg');
}

@font-face {
  font-weight: 700;
  font-family: 'Roboto';
  font-style: normal;
  src: url('~src/assets/fonts/roboto/roboto-v20-latin-700.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-700.woff') format('woff'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-700.ttf') format('truetype'),
    url('~src/assets/fonts/roboto/roboto-v20-latin-700.svg#Roboto') format('svg');
}
