
@use '~styles/variables' as vars;

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: transparent;

  svg {
    color: vars.$gray;
  }

  &:hover,
  &:active {
    svg {
      color: vars.$gray-lighter;
    }
  }
}
