
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.mileage-verification {
  display: flex;
  flex-direction: column;
}

.card-title {
  color: vars.$blue;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 19px;
}

.card {
  display: block !important;
  background-color: vars.$gray;

  @include mixins.media(xs, max) {
    padding: 24px 10px 30px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }
}

.card-content {
  max-width: 535px;
}

.input {
  margin: 16px 0;
}

.submit-button {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;

  @include mixins.media(sm) {
    width: 140px;
  }
}

.title {
  margin-bottom: 12px;
}

@include mixins.media(md, max) {
  :global(.hide-on-mobile) {
    display: none;
  }

  .card-content {
    max-width: initial;
    width: 100%;

    > * {
      width: 100%;
    }
  }
}

@include mixins.media(lg) {
  :global(.hide-on-desktop) {
    display: none;
  }
}
