
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.bottom-text {
  @include typo.text-small;
  text-align: center;
  color: vars.$gray-lighter;

  @include mixins.media(sm) {
    margin: 20px 0;
  }

  a {
    white-space: nowrap;
  }
}
