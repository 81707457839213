
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.change-password {
  display: flex;
  flex-direction: column;

  .title {
    color: vars.$blue;
    margin-bottom: 20px;
  }
}

.card {
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;

  @include mixins.media(sm) {
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: 10px;

  @include mixins.media(sm) {
    margin-top: 20px;
  }
}

.button {
  align-self: flex-start;
  margin-top: 28px;

  @include mixins.media(xs, max) {
    margin-top: 20px;
  }
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: vars.$overlay;
}
