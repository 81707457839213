
@use '~styles/variables' as vars;

.tab-button {
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  justify-content: flex-start;
  font-size: 13px;
  letter-spacing: 0.06em;

  &:hover {
    background-color: vars.$gray;
  }

  &.is-active {
    background-color: vars.$gray;

    &:hover {
      background-color: vars.$gray-darker;
    }
  }
}
