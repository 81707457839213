
@use '~styles/variables' as vars;

.vehicle-card {
  display: block;
  padding: 22px 30px;

  &.revoked .header {
    color: vars.$gray-darkest;

    .vin {
      color: inherit;
    }

    strong {
      color: inherit;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 24px;
  text-transform: capitalize;
}

.vin {
  color: vars.$gray-lighter;
  font-size: 14px;
  text-transform: uppercase;

  strong {
    color: #fff;
  }
}

.edit-button {
  color: vars.$gray-darkest;
  background-color: vars.$gray-icon;
  border-radius: 6px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: vars.$gray-lighter;
  }

  svg {
    transition: color 150ms;
  }
}

.footer {
  color: #98989e;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.tag {
  display: inline-block;
  color: vars.$gray-darkest;
  font-size: 11px;
  text-transform: uppercase;
  border: 1px solid currentColor;
  border-radius: 5px;
  letter-spacing: 0.05em;
  padding: 3px 5px;
  min-width: 170px;
  text-align: center;
}
