
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.account-dropdown {
  position: relative;
  display: flex;

  .dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: vars.$z-index-backdrop-above;
    display: none;

    @include mixins.media(sm) {
      display: block;
      top: 100%;
      left: 50%;
      width: 180px;
      transform: translate(-50%, 20px);

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        width: 24px;
        height: 28px;
        transform: translate(-50%, -50%);
        background: url('../../../../assets/images/icons/rounded-triangle.svg') center no-repeat;
        z-index: -1;
        background-size: contain;
        content: '';
      }
    }
  }

  &[aria-expanded='true'] {
    .avatar {
      z-index: vars.$z-index-backdrop-above;
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    color: #a6acb2;

    button {
      font-size: 0;
    }

    strong {
      color: #fff;
      font-weight: 700;
    }
  }

  .hm-icon {
    color: vars.$gray-darker;
  }

  .user-icon {
    margin-left: 10px;
    color: vars.$gray-darker;
  }

  .button-wrapper {
    position: relative;
  }
}
