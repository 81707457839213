
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='complete-account']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.complete-account {
  display: flex;
  flex-direction: column;
}

.section {
  min-height: 530px;
}

.text {
  @include typo.text-semi-large;
  margin: 20px 0 40px;
  text-align: center;

  @include mixins.media(xs, max) {
    align-self: center;
    width: 100%;
    max-width: 316px;
  }
}

.enter-data {
  //
}

.link-expired {
  .button {
    margin-top: 0;
  }
}

.reset-link-sent {
  //
}

.loading {
  //
}

.card {
  flex-direction: column;
  padding-bottom: 30px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;

  @include mixins.media(sm) {
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: 10px;

  @include mixins.media(sm) {
    margin-top: 20px;
  }
}

.button {
  margin-top: 28px;
  margin-bottom: 40px;
  align-self: center;
  text-transform: uppercase;

  @include mixins.media(xs, max) {
    margin-top: 40px;
  }
}

.hide-on-mobile {
  @include mixins.media(xs, max) {
    display: none;
  }
}

.hide-on-desktop {
  @include mixins.media(sm) {
    display: none;
  }
}
