
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.column-grid {
  display: grid;
  @each $breakpoint in map-keys(vars.$breakpoints) {
    $columns: if(null == index(lg xl, $breakpoint), 1, 2);
    $gap-horizontal: if($breakpoint == xs, grid.$xs-gutter, grid.$gutter);

    @include mixins.media($breakpoint) {
      grid-template-columns: repeat($columns, 1fr);
      gap: 0 $gap-horizontal;
    }
  }
}

.column-grid-column {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;

  @include mixins.media(xs) {
    gap: grid.$xs-gutter 0;
  }

  @include mixins.media(sm) {
    gap: grid.$gutter 0;
  }
}
