
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='forgot-password']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.section {
  @include mixins.media(sm) {
    min-height: 519px;

    &.email-sent {
      min-height: auto;
    }
  }
}

.forgot-password {
  display: flex;
  flex-direction: column;

  @include mixins.media(xs, max) {
    width: 100%;
    max-width: 346px;
    margin: 0 auto;
  }

  @include mixins.media(sm) {
    margin: auto 0;

    .email-sent & {
      margin-top: 40px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.text {
  @include typo.text-semi-large;
  margin: 20px 0 40px;
  text-align: center;

  @include mixins.media(xs, max) {
    align-self: center;
    width: 100%;
    max-width: 316px;
  }
}

.cta {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  a {
    width: 38.11%;
    margin-right: 10px;

    button {
      width: 100%;
    }
  }

  button {
    width: 61.89%;
    text-transform: uppercase;
  }
}

.loader {
  @include mixins.media(sm, max) {
    margin-top: 100px;
  }
}

.error {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
