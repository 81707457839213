
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.hm-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global(.icon-hm) {
    margin-bottom: 20px;

    svg {
      color: vars.$gray-icon;

      @include mixins.media(xs, max) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .title {
    @include typo.text-large;
    font-weight: bold;

    @include mixins.media(sm) {
      @include typo.text-xlarge;
    }

    :global(.light) {
      font-weight: 400;
    }
  }
}
