
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='404']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }

  @include mixins.media(sm) {
    background: url('../../../assets/images/background/not-found.jpg') center center no-repeat;
    background-size: cover;
  }
}

.not-found {
  align-items: center;
  background: vars.$gray-darker;

  @include mixins.media(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .text {
    display: flex;
    margin: 20px auto;
    line-height: 22px;
    text-align: center;

    @include mixins.media(xs, max) {
      max-width: 316px;
      margin-bottom: 40px;
    }
  }

  button {
    width: 200px;
  }

  .title {
    @include typo.text-large;
    white-space: nowrap;

    @include mixins.media(md) {
      @include typo.text-xxlarge;
    }

    @include mixins.media(lg) {
      @include typo.text-ultra-large;
    }
  }
}
