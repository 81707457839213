
.enter-active,
.leave-active {
  transition: opacity 0.125s ease-in;
}

.enter-fade,
.leave-to-fade {
  opacity: 0;
}

.enter-to-fade,
.leave-fade {
  opacity: 1;
}
