
@use '~styles/variables' as vars;

.icon {
  margin: 0 10px;
  padding: 3px;
  cursor: pointer;

  svg {
    color: vars.$gray-lighter;
  }
}
