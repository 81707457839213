// Colors
$blue: #1dd1f8;
$gray-darkest: #313337;
$gray-darker: #3d4045;
$gray: #52565b;
$gray-icon: #676c73;
$gray-light: #62676f;
$gray-lighter: #c6c6c6;
$gray-lightest: #c4c4c4;
$gray-1: #efedec;
$gray-2: #a6acb2;
$gray-3: #f2f2f2;
$gray-text: #e5e5e5;
$green: #249e48;
$red: #d14642;
$overlay: rgba($gray-darker, 0.8);

// Breakpoints
$breakpoints: (
  xs: 0,
  // max sm - 1
  sm: 576px,
  // min : max md - 1
  md: 768px,
  // min : max lg -1
  lg: 992px,
  // min : max xl - 1
  xl: 1200px,
  // min
);

// Containers
$container-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

$header-height: 128px;
$header-margin: 25px;
$header-height-xs: 85px;
$header-margin-xs: 40px;

$z-index: 1;
$z-index-backdrop: $z-index;
$z-index-backdrop-above: $z-index-backdrop + 1;
$z-index-toasts: $z-index-backdrop-above + 1;
