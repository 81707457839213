
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.delete-account {
  display: flex;
  justify-content: center;
  flex-direction: column;

  [role='dialog'] {
    @include mixins.media(xs, max) {
      padding: 30px 20px 45px;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 42px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.text {
  @include typo.text-medium;
  color: #fff;
}

.title {
  color: vars.$blue;
  margin-bottom: 20px;
}
