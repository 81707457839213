
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;

.container {
  display: flex;
}

.card-layout {
  flex-direction: column;

  @include mixins.media(xs, max) {
    @include grid.grid-column-center-xs();
    padding: grid.$xs-grid-gap grid.$xs-grid-gap
      unquote('max(#{grid.$xs-grid-gap}, var(--safe-area-inset-bottom))');
  }

  @include mixins.media(sm) {
    @include grid.grid-column-center();
    justify-content: center;
  }

  @include mixins.media(md) {
    @include grid.grid-column-center(4, 6);
  }

  @include mixins.media(lg) {
    @include grid.grid-column-center(6, 12);
  }
}

.footer-wrapper {
  @include mixins.media(xs, max) {
    margin: auto 0 0;
  }

  @include mixins.media(sm) {
    :not(.static-footer) > & {
      position: absolute;
      bottom: calc(19px - 2 * #{grid.$gutter});
      width: calc(100% - #{grid.$gutter});
      padding: 0 grid.$grid-gap;
    }
  }

  footer {
    @include mixins.media(xs) {
      @include typo.text-xsmall;
      padding-top: grid.$xs-grid-gap;
    }

    @include mixins.media(sm) {
      @include typo.text-small;

      .static-footer & {
        padding: 19px grid.$grid-gap 0;
      }
    }
  }
}
