
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.car-selector {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 0;
}

.dropdown {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: vars.$z-index-backdrop-above;
  width: 530px;
  max-width: 100%;
  padding: 0 20px 20px;
  overflow: hidden;
  background: #676c73;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);

  .caret {
    cursor: pointer;

    @include mixins.media(sm) {
      right: 19px;
    }

    svg {
      color: vars.$gray-darker;
      transform: rotateZ(-90deg);
    }
  }

  > div {
    width: 100%;
  }

  [data-simplebar='init'] {
    overflow: auto;
  }

  [data-simplebar='init'],
  :global(.simplebar-wrapper) {
    display: flex;
    flex-direction: column;
  }
}

.modal {
  z-index: vars.$z-index-backdrop-above + 1;

  @include mixins.media(xs, max) {
    [role='dialog'] {
      padding: 30px 33px 40px;
    }
  }
}

.dropdown-inner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.active-vehicle {
  &:not(:only-child):not(:last-child) {
    margin-bottom: 32px;

    @include mixins.media(sm) {
      margin-bottom: 40px;
    }
  }
}

.vehicle-list {
  > :not(:last-child) {
    margin-bottom: 5px;
  }

  .car-vin {
    border: initial;
  }

  :global(.selected) {
    background-color: vars.$gray;

    &:hover {
      background-color: #45484c;
    }
  }
}

.dropdown-header {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  margin-bottom: 20px;

  .dropdown-title {
    font-size: 20px;
    font-weight: 500;

    span {
      margin-left: 1.5em;
      font-size: 14px;
      font-weight: 400;
      color: #a6acb2;
    }
  }
}
