*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  text-size-adjust: 100%;
}

a,
button {
  color: inherit;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

button {
  padding: 0;
  background-color: transparent;
  border-width: 0;
}

figure {
  margin: 0;
}

button,
input {
  margin: 0;
  padding: 0;
  border-style: none;
  border-width: 0;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  appearance: none;

  &:focus,
  &:active {
    outline: none;
  }
}

input {
  &:optional,
  &:required,
  &:invalid,
  &::-moz-focus-inner,
  &::-moz-ui-invalid,
  &::-moz-submit-invalid,
  &::-moz-autofill,
  &::-moz-autofill-preview {
    margin: 0;
    padding: 0;
    color: inherit;
    border: 0;
    box-shadow: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input::-webkit-autofill-strong-password,
input::-webkit-autofill-strong-password-viewable,
input::-webkit-textfield-decoration-container {
  transition: color 0s ease-out, background-color 0s ease-out;
  transition-delay: 9999s;
  -webkit-text-fill-color: inherit;
}

button,
input,
select,
textarea {
  font: inherit;
}

dd,
dl,
menu,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

p {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border-width: 0;
}

blockquote,
q {
  margin: 0;
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: '';
  content: none;
}

img,
video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: inherit;
}

td,
th {
  padding: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation-play-state: paused !important;
    scroll-behavior: auto !important;
  }
}
