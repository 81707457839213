
@use '~styles/mixins' as mixins;

.permissions {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 10px;

  @include mixins.media(lg) {
    :global(.hide-on-desktop) {
      display: none;
    }
  }
}
