
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.apps-details {
  display: flex;
  flex-direction: column;

  @include mixins.media(lg) {
    .row {
      display: flex;
    }

    .left {
      width: 255px;
      flex-shrink: 0;
      margin-right: 30px;
    }

    .header-row {
      h1 {
        margin-bottom: 16px;
      }

      .left {
        width: auto;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .content-row {
      margin-top: 55px;
    }

    .right {
      flex-grow: 1;
    }

    .hide-on-desktop {
      display: none;
    }
  }

  @include mixins.media(md, max) {
    .hide-on-mobile {
      display: none;
    }
  }
}

.content-row {
  margin-top: 30px;
}

.details {
  display: grid;

  @include mixins.media(md, max) {
    margin-bottom: 40px;
  }
}

.active-car {
  display: inline-block;
  color: vars.$gray-text;
  text-transform: uppercase;
  font-size: 11px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  padding: 4px 6px;
  align-self: center;
  min-width: 255px;
}

.revoke-section {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: 60px;

  .revoke-button {
    align-self: center;
    margin-top: 40px;
  }
}

.title {
  margin-bottom: 16px;
}
