
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.container {
  @include mixins.media(sm) {
    padding-top: vars.$header-height + vars.$header-margin;
  }
}

.container,
.main-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main-layout {
  @include mixins.media(xs, max) {
    @include grid.grid-column-xs();
  }

  @include mixins.media(sm) {
    @include grid.grid-column();
  }
}

.footer-wrapper {
  display: flex;
  margin-top: auto;
  padding: grid.$xs-grid-gap 0 unquote('max(#{grid.$xs-grid-gap}, var(--safe-area-inset-bottom))');

  @include mixins.media(sm) {
    padding: 50px 0 20px;

    footer {
      letter-spacing: -0.02em;
    }
  }
}
