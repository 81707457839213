
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.app-list-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:active,
  &:hover {
    cursor: pointer;

    .details {
      color: #fff;
    }

    .card {
      background: vars.$gray-darker;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .thumbnail {
    margin-right: 10px;
  }

  .details {
    @include mixins.text-ellipsis;
    flex: 1;
    margin-right: 12px;
    color: #98989e;

    .name {
      color: #fff;
      font-weight: bold;
    }
  }

  a {
    margin-left: auto;
  }
}

.text {
  @include typo.text-xlarge;

  @include mixins.media(sm) {
    @include typo.text-xxlarge;
  }
}

.card {
  flex-direction: column;
  padding: 20px;
  background-color: vars.$gray;
  transition: background-color 150ms;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: vars.$blue;

  &,
  > * {
    display: flex;
  }

  .card-header-title {
    margin-right: 10px;
    font-weight: 500;
  }

  svg {
    color: vars.$gray-icon;
  }
}

.permissions {
  @include mixins.gap(4px);
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  padding-top: 35px;
  border-top: 1px solid vars.$gray-icon;
  text-transform: capitalize;
}

.time-label {
  @include mixins.text-ellipsis;
  @include typo.text-medium-alt;
  margin-top: 40px;
  color: #98989e;
  text-align: center;
}
