
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

:global([data-page='reset-password']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.reset-password {
  min-height: 485px;
  @include mixins.media(sm) {
    padding: 80px;
  }

  .form {
    margin-top: 40px;
  }

  .input {
    margin-bottom: 10px;
  }

  .text {
    margin-bottom: 40px;
    text-align: center;
  }

  .cta {
    margin-top: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;

  @include mixins.media(xs, max) {
    width: 100%;
    max-width: 346px;
    margin: 0 auto;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  a,
  button {
    width: 200px;
    text-transform: uppercase;
  }
}

.error {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
