
@use '~styles/mixins' as mixins;

.apps {
  display: flex;
  flex-direction: column;
}

.apps-list {
  display: grid;
  margin: 30px 0 35px;

  @include mixins.media(sm) {
    margin: 48px 0 15px;
  }
}
