
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

:global([data-page='sign-in']) body {
  background: url('../../../assets/images/background/sign-in.png') bottom right no-repeat;
  background-size: cover;

  @include mixins.media(sm) {
    background-image: url('../../../assets/images/background/sign-in-sm.jpg');
  }
}

.section {
  justify-content: center;
  background: transparent;
  min-height: 590px;

  @include mixins.media(sm) {
    background: vars.$gray-darker;
  }
}

.sign-in {
  display: flex;
  flex-direction: column;

  @include mixins.media(sm) {
    margin: auto 0;

    h2 {
      margin-top: 20px;
    }
  }

  .title {
    margin-bottom: 40px;
  }

  .heading {
    margin-top: 20px;
    font-size: 21px;
  }

  .title-icon {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    @include mixins.media(xs, max) {
      width: 100%;
      max-width: 356px;
      margin: 0 auto;
    }

    > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .form-error {
    margin-top: 10px;

    @include mixins.media(sm) {
      margin-top: 20px;
    }
  }

  .cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    button {
      width: 200px;
    }
  }

  .forgot-password {
    margin-top: 40px;
    text-align: center;
    cursor: pointer;

    a {
      display: inline-block;
      margin-top: 1.5px;
      color: vars.$gray-lighter;
      border-bottom: 1px solid vars.$gray-lighter;

      &:hover,
      &:active {
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
