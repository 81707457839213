@use './functions' as fn;
@use './mixins' as mixins;
@use './variables' as vars;

@import 'simplebar';

:root {
  --safe-area-inset-top: 0;
  --safe-area-inset-right: 0;
  --safe-area-inset-bottom: 0;
  --safe-area-inset-left: 0;

  @supports (top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mixins.media(xs, max) {
    width: 100vw;
    min-height: fn.vh(100);
  }
}

body {
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  background-color: vars.$gray-icon;
}

:global(.no-scroll) {
  overflow: hidden;

  @include mixins.media(xs, max) {
    position: fixed;
  }
}

a {
  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    outline: none;
  }
}
