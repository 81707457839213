
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.permissions-card {
  display: flex;
  flex-direction: column;

  @include mixins.media(xs, max) {
    padding: 20px 20px 30px;
  }

  .title {
    color: vars.$blue;
    font-size: 22px;
    font-weight: 500;
  }
}

.tag {
  align-self: flex-start;
  margin-bottom: 20px;
  margin-top: 16px;
}

.text {
  @include mixins.media(sm) {
    margin-bottom: 10px;
  }
}

.text {
  color: vars.$gray-text;
}

.footer {
  text-align: center;
  color: #98989e;
  font-size: 14px;
  margin-top: 25px;

  strong {
    color: vars.$gray-text;
  }
}
