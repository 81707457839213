
@use '~styles/variables' as vars;

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: vars.$z-index-backdrop;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
}
