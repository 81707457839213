
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

@import '~styles/mixins';
@import '~styles/typography';
@import '~styles/variables';

.menu {
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background: $gray;
  width: 340px;
  padding: 5px;
  border-radius: 6px;

  &.no-marker {
    .label {
      color: #fff;
    }
  }
}
