
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.menu-item {
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  justify-content: center;
  background: vars.$gray;

  @include typo.text-medium-alt();
  align-items: center;
  padding: 20px 23px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 150ms;

  .label {
    @include mixins.text-ellipsis();
    margin-right: 10px;
    overflow: hidden;
    color: vars.$gray-lightest;
  }

  .marker {
    position: relative;
    display: flex;
    width: 10px;
    height: 10px;
    margin-left: auto;

    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 50%;
      content: '';
    }
  }

  &:hover,
  &:active,
  &.selected,
  .no-marker & {
    .label {
      color: #fff;
    }
  }

  &:hover,
  &:active {
    background: #3d4045;
  }
}
