
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.toast-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: vars.$z-index-toasts;
}

.wrapper {
  position: relative;
  pointer-events: none;

  @include mixins.media(sm, max) {
    margin: 0 10px 10px;
  }

  @include mixins.media(md) {
    margin-bottom: 30px;
  }
}

.toast-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.toast {
  pointer-events: all;
}

.slide-fade-enter-active {
  transition: transform ease-in-out 0.2s, opacity ease-in 0.2s;
}

.slide-fade-leave-active {
  transition: transform ease-out 0.3s, opacity ease-out 0.3s;
}
