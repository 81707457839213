
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.header {
  display: flex;

  @include mixins.media(xs, max) {
    margin-bottom: vars.$header-margin-xs;

    &.no-avatar {
      .avatar {
        display: none;
      }
    }
  }

  @include mixins.media(sm) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: vars.$z-index;
    height: vars.$header-height;
    padding: 20px 0;

    &.background {
      background: rgba(61, 64, 69, 0.9);
    }
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mixins.media(xs, max) {
    z-index: vars.$z-index;
    padding: grid.$xs-grid-gap;
    padding-top: 17px;
  }

  @include mixins.media(sm) {
    .avatar {
      margin-left: 20px;
    }

    .badge {
      margin-right: 20px;
    }
  }

  > *:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  > *:nth-child(2):not(:last-child) {
    flex-basis: 100%;
    max-width: 530px;
    white-space: nowrap;
  }

  > *:nth-child(1),
  > *:nth-child(3) {
    flex-basis: 50%;
  }

  > *:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
}

.back-button-wrapper {
  display: flex;
  align-items: center;

  span {
    display: none;
    font-weight: 700;
    margin-left: 20px;

    @include mixins.media(lg) {
      display: inline;
    }
  }
}
