
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.container,
.container-fluid {
  width: 100%;
  min-width: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint, $max-width in vars.$container-widths {
    @include mixins.media($breakpoint) {
      max-width: $max-width;
    }
  }
}
