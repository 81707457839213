
@use '~styles/functions' as fn;
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;

$margin: 2 * grid.$gutter;

.page-container {
  display: flex;

  @include mixins.media(xs) {
    &,
    > div {
      min-height: fn.vh(100);
    }
  }

  @include mixins.media(sm) {
    &.margins {
      margin: $margin 0;

      &,
      > div {
        min-height: calc(#{fn.vh(100)} - 2 * #{$margin});
      }
    }
  }
}
