
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.cookies-bar {
  position: fixed;
  bottom: 20px;
  z-index: vars.$z-index-backdrop-above;
  display: flex;
  justify-content: center;
  width: 100%;

  @include mixins.media-only(xs) {
    bottom: grid.$xs-grid-gap;
  }

  .bar {
    display: flex;
    align-items: center;
    margin: 0 grid.$grid-gap;
    padding: 15px;
    background: #1c1d1f;
    border-radius: 12px;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3));

    @include mixins.media-only(xs) {
      flex-direction: column;
      margin: 0 grid.$xs-grid-gap;
      padding: 15px 15px 10px;

      .message {
        text-align: center;
      }
    }

    .message {
      @include typo.text-medium;
      margin: 0 5px;
      color: vars.$gray-lighter;
    }
  }

  .button {
    width: 100px;
    margin-left: 15px;

    @include mixins.media-only(xs) {
      position: relative;
      width: 54px;
      height: 39px;
      margin: 0;
      padding: 0;

      &,
      &:active,
      &:focus,
      &:hover {
        @include typo.text-medium-alt;
        color: #fff;
        background: transparent;
      }

      &:active {
        outline: 1px dotted vars.$gray-lighter;
      }

      &::after {
        position: absolute;
        bottom: 10px;
        left: 50%;
        display: block;
        width: 33px;
        border-bottom: 1px solid #fff;
        transform: translate3d(-50%, 0, 0);
        content: '';
      }
    }
  }
}
