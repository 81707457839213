
@use '~styles/variables' as vars;

.card {
  display: flex;
  padding: 28px;
  background: vars.$gray;
  border-radius: 12px;
  overflow: hidden;
}
