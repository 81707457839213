
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.car-vin {
  display: flex;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  border: 1px solid #494d52;
  border-radius: 6px;
  cursor: pointer;

  :global(.icon-car) {
    svg {
      width: 25px;
      height: auto;
    }
  }
}

.details-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-left: 7px;
  padding-right: 7px;

  .brand {
    @include mixins.text-ellipsis;
    @include typo.title-small;
    color: vars.$gray-3;
    text-align: center;

    @include mixins.media(md) {
      @include typo.title-medium;
    }
  }
}

.vin {
  @include mixins.text-ellipsis;
  @include typo.text-medium;
  color: vars.$gray-lighter;
  text-align: center;

  span {
    font-weight: bold;
    color: #fff;
  }
}

.list-item {
  padding: 14px;
  transition: background-color 150ms;

  &:hover,
  &:active {
    background-color: vars.$gray;
  }

  :global(.icon-car),
  :global(.icon-pen) {
    background-color: #676c73;
    width: 60px;
    height: 60px;
    border-radius: 5px;

    svg {
      color: vars.$gray-darkest;
    }
  }

  .select-label {
    @include typo.text-medium-alt;
    color: vars.$gray-darkest;

    &:hover {
      color: vars.$gray-lighter;
    }

    svg {
      color: inherit;
      transition: color 150ms, fill 150ms;
    }
  }
}

.selector {
  padding: 20px 20px 20px 11px;
  transition: background-color 150ms, border-color 150ms;

  :global(.icon-car) {
    width: 44px;
    height: 44px;
    background-color: #494d52;
    border-radius: 5px;

    svg {
      color: vars.$gray-icon;
    }
  }

  :global(.icon-arrow-right) {
    margin-left: 10px;

    svg {
      color: vars.$gray-darker;
      transform: rotate(90deg);
    }
  }

  &:active,
  &:hover {
    background: vars.$gray;
    border-color: transparent;
  }
}
