
@use '~styles/variables' as vars;

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  background: vars.$overlay;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.spinner {
  position: relative;
  width: 57px;
  height: 57px;
}

.bounce-a,
.bounce-b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
  animation: bounce 2s infinite ease-in-out;
}

.bounce-a {
  opacity: 0.4;
}

.bounce-b {
  opacity: 0.6;
  animation-delay: -1s;
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
