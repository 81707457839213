
@use '~styles/functions' as fn;
@use '~styles/mixins' as mixins;

.car-selector,
.car-selector-wrapper {
  display: none;

  @include mixins.media(sm) {
    display: flex;
  }
}

.car-selector-wrapper {
  flex: 1;

  @include mixins.media(sm) {
    max-width: 530px;

    [role='menu'] {
      max-height: calc(#{fn.vh(100)} - 2 * 20px);

      :global(.simplebar-content) {
        position: relative;

        /* stylelint-disable-next-line max-nesting-depth, selector-max-compound-selectors */
        > div:last-child {
          overflow-x: hidden;
        }
      }
    }
  }
}

.hide-overflow {
  @include mixins.media(xs, max) {
    > div {
      overflow: hidden;
    }
  }

  @include mixins.media(sm) {
    overflow: hidden;
  }
}
