
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='confirm-email']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.confirm-email {
  display: flex;
  flex-direction: column;
}

.section {
  min-height: 530px;
}

.text {
  @include typo.text-semi-large;
  margin: 20px 0 40px;
  text-align: center;

  @include mixins.media(xs, max) {
    align-self: center;
    width: 100%;
    max-width: 316px;
  }
}

.bottom-text {
  @include typo.text-small;
  text-align: center;
  color: vars.$gray-lighter;

  @include mixins.media(sm) {
    margin: 20px 0;
  }

  a {
    white-space: nowrap;
  }
}

.link-expired {
  .button {
    margin-top: 0;
  }
}

.invalid-token {
  //
}

.loading {
  //
}

.success {
  //
}

.error {
  //
}

.card {
  flex-direction: column;
  padding-bottom: 30px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.hide-on-mobile {
  @include mixins.media(xs, max) {
    display: none;
  }
}

.hide-on-desktop {
  @include mixins.media(sm) {
    display: none;
  }
}
