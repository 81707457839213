
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.footer {
  @include typo.text-medium;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  color: vars.$gray-lighter;

  @include mixins.media(xs, max) {
    @include typo.text-small(17px);
  }

  a {
    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #fff;
      font-weight: bold;
    }
  }

  > div {
    display: flex;

    &:first-child {
      margin-right: auto;
    }
  }
}

.legal {
  a {
    margin-right: 8px;

    @include mixins.media(sm) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
