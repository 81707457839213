
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.container {
  @include mixins.media(sm) {
    padding-top: vars.$header-height + vars.$header-margin;
  }
}

.container,
.account-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.account-layout {
  @include mixins.media(xs, max) {
    @include grid.grid-column-xs();
  }

  @include mixins.media(sm) {
    @include grid.grid-column();
  }
}

.footer-wrapper {
  display: flex;
  margin-top: auto;
  padding: grid.$xs-grid-gap 0 unquote('max(#{grid.$xs-grid-gap}, var(--safe-area-inset-bottom))');

  @include mixins.media(sm) {
    padding: 50px 0 20px;

    footer {
      letter-spacing: -0.02em;
    }
  }
}

.page-title {
  display: flex;
  align-items: center;
  color: #e5e5e5;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;

  @include mixins.media(lg) {
    font-size: 36px;
  }

  strong {
    font-weight: 700;
    color: #fff;
  }
}

@include mixins.media(lg) {
  .row {
    display: flex;
  }

  .left {
    width: 255px;
    flex-shrink: 0;
    margin-right: 30px;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-row {
    margin-top: 25px;

    .right {
      max-width: 635px;
    }
  }

  .right {
    flex-grow: 1;
  }

  .hide-on-desktop {
    display: none;
  }
}

@include mixins.media(md, max) {
  .hide-on-mobile {
    display: none;
  }
}

.vehicle-card + .vehicle-card {
  margin-top: 2px;
}

.my-vehicles-button {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #52565b;
  border-radius: 10px;
  padding: 24px 10px;
  width: 100%;

  svg {
    color: #676c73;
  }
}

.title-label {
  display: inline-flex;
  align-items: center;
  margin-left: 20px;

  span {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-weight: 500;
    color: #fff;
  }

  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}
