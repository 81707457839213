
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.tag {
  display: inline-flex;
  color: vars.$gray-3;
  font-weight: 500;
  background-color: #000;
  border-radius: 8px;

  &.small {
    @include typo.text-medium-alt;
    padding: 10px 25px;
  }

  &.large {
    @include typo.text-large;
    padding: 6px 20px;
  }
}
