
@use '~styles/typography' as typo;

.text {
  &.medium {
    @include typo.text-medium;
  }

  &.large {
    @include typo.text-large;
  }

  &.xlarge {
    @include typo.text-xlarge;
  }
}
