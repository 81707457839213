
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.modal-container {
  overflow-y: auto;
}

.modal-wrapper {
  width: 100%;
  min-height: calc(100% - 2 * #{grid.$gutter});
  padding: 0 grid.$grid-gap;

  @include mixins.media(xs, max) {
    min-height: calc(100% - 2 * #{grid.$xs-gutter});
    padding: 0 grid.$xs-grid-gap;
  }
}

.modal {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  padding: 30px 20px;
  background: vars.$gray-light;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  @include mixins.media(xs, max) {
    padding: 30px 30px 40px;
  }

  .close-button {
    position: absolute;
    top: -7px;
    right: -7px;

    svg {
      color: vars.$gray-darker;
    }
  }
}

.modal-inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  > *:not(:only-child):not(:last-child) {
    margin-bottom: 20px;
  }

  .text,
  .title {
    word-wrap: break-word;
  }

  .text {
    color: vars.$gray-text;
  }

  .body,
  .footer {
    display: flex;
  }

  .footer-inner {
    @include mixins.gap(10px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .footer-inner > button:only-child {
    flex: 0 1 200px;
  }

  .footer-inner > button:not(:only-child) {
    min-width: 140px;

    @include mixins.media(xs, max) {
      flex-grow: 1;
      min-width: auto;
    }
  }
}
