@use './variables' as vars;
@use './functions' as fn;

@mixin center-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@mixin center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin gap($gap-horizontal, $gap-vertical: $gap-horizontal) {
  width: calc(100% + #{$gap-horizontal});
  margin-top: -$gap-vertical;
  margin-left: -$gap-horizontal;

  > * {
    margin-top: $gap-vertical;
    margin-left: $gap-horizontal;
  }
}

@mixin gap-horizontal($gap) {
  width: calc(100% + #{$gap});
  margin-left: -$gap;

  > * {
    margin-left: $gap;
  }
}

@mixin gap-vertical($gap) {
  margin-top: -$gap;

  > * {
    margin-top: $gap;
  }
}

@mixin media($breakpoint, $type: min) {
  @if map_has_key(vars.$breakpoints, $breakpoint) {
    $width: fn.get-breakpoint-width($breakpoint);
    @if ($type == max) {
      $breakpoint: fn.get-next-breakpoint($breakpoint);
      $width: fn.get-breakpoint-width($breakpoint) - 1;
    }
    @media (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin media-only($breakpoint) {
  @if map_has_key(vars.$breakpoints, $breakpoint) {
    $up: fn.get-next-breakpoint($breakpoint);

    @if ($breakpoint == $up) {
      @include media($breakpoint, min) {
        @content;
      }
    } @else {
      $min: fn.get-breakpoint-width($breakpoint);
      $max: fn.get-breakpoint-width($up) - 1;
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    }
  }
}

@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
