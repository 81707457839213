
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.mobile-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: vars.$gray-icon;

  @include mixins.media(sm) {
    display: none;
  }

  .car-selector {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  .current-vehicle {
    border: 1px solid #494d52;
    border-radius: 5px;
    margin-top: 50px;
    padding: 20px;

    button {
      border-radius: 5px;
      min-width: 60%;
      justify-content: space-between;
      background: vars.$gray;
      color: #fff;
    }

    > *:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  header {
    text-align: center;
    padding: 20px 15px;
    font-size: 18px;
    color: vars.$gray-2;

    strong {
      color: vars.$gray-3;
    }
  }

  .active-car-icon {
    color: #494d52;
    border: 1px solid #494d52;
    border-radius: 5px;
    width: 44px;
    height: 44px;

    svg {
      width: 26px;
      color: inherit;
    }
  }

  .active-car-title {
    margin-top: 15px;
    font-size: 24px;
    line-height: 28px;
    color: #cdced0;

    strong {
      color: vars.$gray-3;
    }
  }

  .links {
    background: none;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;

    > * {
      margin-bottom: 10px;
      justify-content: flex-start;
    }
  }

  .close-button {
    margin-top: auto;
    align-self: center;
    min-width: 140px;
  }

  .icon-arrow-right {
    svg {
      color: vars.$gray-icon;
    }
  }

  .car-vin {
    border: initial;
  }

  :global(.selected) {
    background-color: vars.$gray;
  }

  .vehicle-header {
    color: #fff;

    .back {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
    }

    :global(.title) {
      font-size: 20px;
      font-weight: 500;
      position: relative;
    }

    :global(.subtitle) {
      color: vars.$gray-2;
      font-size: 14px;
      margin-top: 15px;
    }

    strong {
      color: inherit;
    }
  }

  [data-simplebar='init'] {
    overflow-y: auto;
    height: 100%;
    margin-bottom: 10px;
  }
}
