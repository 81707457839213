
@use '~styles/variables' as vars;

.application-thumbnail {
  align-self: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #cdced0;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    background-color: vars.$gray-3;
  }
}
