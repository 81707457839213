
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;

.title {
  display: flex;

  &.small {
    @include typo.title-small();
  }

  &.medium {
    @include typo.title-medium();
  }

  &.large,
  &.large-alt {
    @include typo.title-large-xs();

    @include mixins.media(md) {
      @include typo.title-large-medium();
    }
  }

  &.large-alt {
    @include mixins.media(md) {
      @include typo.title-large-alt();
    }
  }
}
