
.enter-active,
.leave-active {
  transition: transform ease-in 0.3s, opacity ease-in 0.3s;
}

.enter-top,
.leave-to-top {
  transform: translateY(-100%);
  opacity: 0;
}

.enter-right,
.leave-to-right {
  transform: translateX(100%);
  opacity: 0;
}

.enter-left,
.leave-to-left {
  transform: translateX(-100%);
  opacity: 0;
}

.enter-bottom,
.leave-to-bottom {
  transform: translateY(100%);
  opacity: 0;
}

.enter-to,
.leave {
  transform: translateX(0) translateY(0);
  opacity: 1;
}
