
@use '~styles/mixins' as mixins;

.page-title {
  color: #e5e5e5;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;

  @include mixins.media(lg) {
    font-size: 36px;
  }

  strong {
    font-weight: 700;
    color: #fff;
  }
}

.left a {
  img {
    margin-left: auto;
  }
}

.alerts {
  margin-top: 20px;
}

@include mixins.media(lg) {
  .row {
    display: flex;
  }

  .left {
    width: 255px;
    flex-shrink: 0;
    margin-right: 30px;

    .with-notification {
      height: 50px;
      padding: 5px 15px 5px 25px;
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-row {
    margin-top: 55px;
  }

  .right {
    flex-grow: 1;
  }

  .hide-on-desktop {
    display: none;
  }
}

@include mixins.media(md, max) {
  .hide-on-mobile {
    display: none;
  }

  .right {
    display: flex;
    flex-direction: column;

    .first {
      display: flex;
      order: -1;
      margin-bottom: 28px;
    }

    > div:last-child:not(.first) {
      margin-bottom: 40px;
    }
  }
}

.content-row {
  margin-top: 25px;
}
