
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.no-data {
  background: vars.$gray;
  border-radius: 10px;
  padding: 20px 48px 20px 20px;
  min-height: 270px;

  @include mixins.media(sm) {
    max-width: 350px;
  }
}

.title {
  @include typo.text-semi-large;
  color: vars.$gray-lightest;
  margin-bottom: 16px;
  font-weight: bold;
}

.text {
  @include typo.text-medium;
  color: #fff;
}
