@use 'sass:math';

$gutter: 30px;
$grid-gap: math.div($gutter, 2);

$xs-gutter: 20px;
$xs-grid-gap: math.div($xs-gutter, 2);

$grid-column-counts: (
  xs: 4,
  sm: 6,
  md: 6,
  lg: 12,
  xl: 12,
);

@function get-grid-column-count($breakpoint) {
  @if map_has_key($grid-column-counts, $breakpoint) {
    @return map_get($grid-column-counts, $breakpoint);
  }
  @return 12;
}

@mixin grid-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin grid-column(
  $columns: 1,
  $total-columns: $columns,
  $offset-left: 0,
  $offset-right: 0,
  $xs: false
) {
  $column-width: calc(100% / #{$total-columns});

  position: relative;
  right: calc(#{$column-width} * #{$offset-right});
  left: calc(#{$column-width} * #{$offset-left});
  display: flex;
  width: calc(#{$column-width} * #{$columns});
  padding-right: if($xs == true, $xs-grid-gap, $grid-gap);
  padding-left: if($xs == true, $xs-grid-gap, $grid-gap);
}

@mixin grid-column-xs($columns: 1, $total-columns: $columns, $offset-left: 0, $offset-right: 0) {
  @include grid-column($columns, $total-columns, $offset-left, $offset-right, true);
}

@mixin grid-column-center($columns: 1, $total-columns: $columns) {
  @include grid-column($columns, $total-columns, math.div(($total-columns - $columns), 2));
}

@mixin grid-column-center-xs($columns: 1, $total-columns: $columns) {
  @include grid-column-xs($columns, $total-columns, math.div(($total-columns - $columns), 2));
}
