
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.verification-modal {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  width: 100%;
  text-align: center;

  .title {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  .image {
    margin-top: 50px;
    margin-bottom: 35px;

    &.checkmark-wrapper {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        width: 40px;
        height: 40px;
        background: url('../../../../assets/images/icons/ico-check-circle-green-full.svg') center
          no-repeat;
        background-size: contain;
      }
    }

    &.icons {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        margin: 0 13px;
      }
    }
  }

  .app-icon {
    display: flex;
  }

  .car-icon {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: vars.$gray;
    border-radius: 12px;

    svg {
      color: vars.$gray-lighter;
    }
  }

  .actions {
    text-align: center;
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
}

.verification-modal:not(.first-verification) {
  .image {
    margin-top: 62px;
  }
}

.modal {
  [role='dialog'] {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include mixins.media(xs, max) {
      max-width: 356px;
    }
  }
}
