
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.text {
  display: flex;
  flex-direction: column;
}

.text {
  color: vars.$gray-text;

  > :first-child {
    margin-bottom: 20px;
  }

  > :last-child {
    margin-bottom: 10px;
  }
}

@include mixins.media(xs, max) {
  .hide-xs {
    display: none;
  }
}
