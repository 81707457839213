@mixin text-xsmall {
  font-size: 11px;
  line-height: 13px;
}

@mixin text-small($line-height: 14px) {
  font-size: 12px;
  line-height: $line-height;
}

@mixin text-medium {
  font-size: 14px;
  line-height: 20px;
}

@mixin text-medium-alt {
  font-size: 14px;
  line-height: 16px;
}

@mixin text-semi-large {
  font-size: 16px;
  line-height: 22px;
}

@mixin text-large($line-height: 21px) {
  font-size: 18px;
  line-height: $line-height;
}

@mixin text-xlarge($line-height: 25px) {
  font-size: 21px;
  line-height: $line-height;
}

@mixin text-xxlarge {
  font-size: 24px;
  line-height: 28px;
}

@mixin text-ultra-large {
  font-size: 36px;
  line-height: 60px;
}

@mixin text-huge {
  font-size: 42px;
  line-height: 49px;
}

@mixin title-small {
  @include text-xlarge;
  font-weight: 500;
}

@mixin title-medium {
  @include text-xxlarge;
  font-weight: bold;
}

@mixin title-large-xs {
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  letter-spacing: -0.02em;
}

@mixin title-large-medium {
  @include text-ultra-large;
  font-weight: bold;
  letter-spacing: -0.02em;
}

@mixin title-large-alt {
  @include text-huge;
  font-weight: bold;
  letter-spacing: normal;
}
