
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.about-card {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: vars.$gray;

  @include mixins.media(xs, max) {
    padding: 20px 20px 30px;
  }

  @include mixins.media(lg) {
    padding-bottom: 40px;
    flex-direction: row;

    .details {
      padding-left: 40px;
    }
  }

  // .thumbnail {
  //   margin-bottom: 24px;
  // }

  .details {
    display: flex;

    @include mixins.media(xs, max) {
      flex-direction: column;

      .description:not(:last-child) {
        margin-bottom: 20px;
      }

      button,
      .link {
        align-self: center;
      }
    }

    @include mixins.media(sm) {
      .description:not(:last-child) {
        margin-right: 20px;
      }

      button {
        width: 140px;
      }

      .link {
        align-self: flex-start;
      }
    }

    > * {
      display: flex;
    }

    .description {
      flex: 1;
      color: vars.$gray-text;
    }
  }
}

.title {
  margin-bottom: 10px;
}

@include mixins.media(lg) {
  :global(.hide-on-desktop) {
    display: none;
  }
}
