
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.text-input {
  display: flex;
  flex-direction: column;

  .error {
    @include typo.text-medium();
    margin: 5px 15px 0;
    color: #ff7373;
  }
}

.wrapper-outer {
  display: flex;
  height: 60px;
  padding: 0 18px;
  background: vars.$gray-darkest;
  border: 2px solid transparent;
  border-radius: 6px;

  &.focused {
    border-color: vars.$gray-lighter;

    .placeholder {
      color: vars.$gray-lighter;
    }
  }

  &.has-error {
    border-color: #ff7373;
  }

  &.has-right-component {
    padding-right: 0;
  }

  .wrapper-inner {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    &.with-placeholder {
      justify-content: flex-start;
      margin-top: 10px;

      input {
        color: #fff;
        -webkit-text-fill-color: #fff;
      }

      input::-webkit-credentials-auto-fill-button {
        margin-top: -17px;
      }
    }
  }

  .placeholder {
    @include mixins.text-ellipsis();
    @include typo.text-small();
    display: flex;
    align-self: flex-start;
    margin-bottom: 3px;
    color: vars.$gray-light;
  }

  .right {
    display: flex;
    align-items: center;
  }

  input {
    @include mixins.text-ellipsis();
    display: flex;
    width: 100%;
    min-width: 0;
    color: vars.$gray-light;
    caret-color: #fff;
    font-size: 100%;
    line-height: 19px;
    background-color: vars.$gray-darkest;
    box-shadow: 0 0 0 1000px vars.$gray-darkest inset;
    -webkit-text-fill-color: vars.$gray-light;

    &::-webkit-credentials-auto-fill-button {
      background-color: vars.$gray-lighter;
    }
  }
}
