
@use '~styles/variables' as vars;

.alert {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 10px;
}

.content {
  margin-top: 4px;
  margin-left: 6px;

  a {
    text-decoration: underline;
  }
}

.error {
  background-color: #fff2f2;
  color: #c83a3a;
}

.warning {
  background-color: #fff7e0;
  color: vars.$gray;
}
