
@use '~styles/mixins' as mixins;

.vehicle-data {
  display: flex;
  margin-bottom: 28px;

  @include mixins.media(md, max) {
    flex-direction: column;
  }

  .title {
    margin-bottom: 12px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    margin-bottom: 80px;
  }
}

@include mixins.media(lg) {
  :global(.hide-on-desktop) {
    display: none;
  }
}
