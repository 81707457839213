
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.personal-information {
  display: flex;
  flex-direction: column;

  .title {
    color: vars.$blue;
    margin-bottom: 20px;
  }
}

.card {
  position: relative;
  flex-direction: column;
  padding-bottom: 30px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;

  @include mixins.media(sm) {
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: 10px;

  @include mixins.media(sm) {
    margin-top: 20px;
  }
}

.button {
  align-self: flex-start;
  margin-top: 28px;

  @include mixins.media(xs, max) {
    margin-top: 20px;
  }
}
